<template>
  <confetti-party v-if="/localhost/.test(host)" />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ConfettiParty from '@/vue/ConfettiParty.vue';
export default defineComponent({
  components: {
    'confetti-party': ConfettiParty,
  },
  data: () => ({
    host: window.location.host
  }),
});
</script>
